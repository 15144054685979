


const Recovery = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false" >
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/work.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <main>
                <div class="container">
                    <br />
                    <h2 class=" title font-weight-bold wow fadeIn">RECOVERY METHOD</h2>
                    <br />
                    <h4 class="display-8  wow fadeIn">1. AMICABLE SETTLEMENT METHOD</h4>
                    <p>This method includes all recovery efforts that are required before reaching the stage of Amicable Settlement Method through Negotitation and Meditation. IDR has two plans under this method:</p>
                    <section class="team-section pb-5 mb-4">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Basic
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        Under this debt recovery procedure, IDR adopts a very friendly approach with the defaulting debtor. IDR understands that the debtor is the ultimate customer of its client and takes all steps carefully to maintain that sensitive relationship whilst applying the debt recovery activities. Below are the Debt Recovery activities that we undertake under this method:
                                        <li>1.Regular Email escalation</li>
                                        <li>2.Regular telephonic follow-up</li>
                                        <li>3.Minor Accounts Reconciliation</li>
                                        <li>4.Regular follow-up through Letters</li>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Premium
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                        Under this recovery method, IDR starts with Friendly Approach and gradually shifting its approach from Friendly to Legal, depending on the response of the defaulting debtors. The whole purpose of Legal Negotiation procedure is to create Legal Pressure and fear in the mind of the defaulting debtor to seek positive response from the defaulting debtor. The whole purpose is to force debtor to come forward for settlement by creating as much trouble as we can, but with prior consultation with our client. This method of recovery is applied when the debtor does not come forward amicably. Below are the Recovery activities that we undertake under this method/plan:
                                        <li>1.Regular Email escalation.</li>
                                        <li>2.Regular telephonic follow-up</li>
                                        <li>3.Personal Visit (1time)</li>
                                        <li>4.Minor Accounts Reconciliation</li>
                                        <li>5.Regular follow-up through Letters</li>
                                        <li>6.Legal Notice and Rejoinders</li>
                                        <li>7.Drafting of FIR</li>
                                        <li>8.Drafting of Complaint to Regulatory Agencies</li>
                                        <p><span style={{"fontWeight":"bold"}}>TIME LINE</span>: IDR will undertake the above stated Debt Recovery Activities for a period of 60 to 90 days. After that, IDR will close the account with the prior intimation to the client.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <h4 class="display-8  wow fadeIn">  2. LITIGATION METHOD</h4>
                        <p>Under this method, IDR adopt the approach of recovering the debts through court of law and/or through Police authorities and filing suit for recovery ,winding up petitions,filing of criminal complaints with Police authority, filing complaint under section 138 of the Negotiable Instrument Act for dishonouring of cheque and also by using all other Legal remedies available as per the circumstances and facts of each claim.</p>
                        <p>IDR has in-house Law firm and also have strategic Partnership with over 150 leading Law firms across india in almost all the cities in India.
                            Under this method IDR offer the following 2 options to its clients:</p>
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingthree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapsethree" aria-expanded="true" aria-controls="collapsethree">
                                            Litigation Support Services
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapsethree" class="collapse show" aria-labelledby="headingthree" data-parent="#accordion">
                                    <div class="card-body">
                                        Under this option, IDR identifies the suitable law firm as per the facts and circumstances of the claim and refer to its client for interviewing, examining and engagement of law firm. Under this option, client selects and engage the law firm and directly settle its fee including terms and contions directly with the law firm selected by the client. However, under this option,IDR provide the following services:
                                        <li>1.Identification of suitable law firm for engagement by the client.</li>
                                        <li>2.To follow up with the engaged law firm and provide all the information, documents, settlement of queries and track the status of progress of legal case and all other required support. In short , IDR act as a bridge between law firm and its client.</li>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingfour">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                            Full Litigation Solutions
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapsefour" class="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                    <div class="card-body">
                                        Under this option, IDR handles the full Litigation itself right from engaging the lawyer and its results. This option is available to the clients if the claim value is more than Rs. 5 Million (USD 10000). Under this option IDR provide the following services :-
                                        <li>1.Advice on type of Litigation to be chosen such as Law Suits, Winding Up , Criminal Complaint / FIR, Complaint under Section 138 of Negotiable Instruments Act (Dishonour of Cheques).</li>
                                        <li>2.Tele-Conference with the Client.</li>
                                        <li>3.Drafting and serving formal legal notice.</li>
                                        <li>4.Preparation of petition, lawsuits or criminal complaints etc.</li>
                                        <li>5.Seeking approval of draft from the client.</li>
                                        <li>6.Filing & representing to and before appropriate civil or criminal authority against the debtor, as the case may be.</li>
                                        <li>7.Pursuing the litigation & obtaining the orders from the appropriate judicial courts or quasi judicial authorities.</li>
                                        <li>8.Execution of Court Decree / Judgement.</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>

        </>
    );
};

export default Recovery;

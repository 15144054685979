


const Appro = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false" >
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/work.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{"width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px"  }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <main>
                <div class="container">
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-5">
                            <div class="col-lg-12 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <h3 class="mb-5 dark-grey-text title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                    <strong>OUR APPROACH</strong>
                                </h3>
                                <p align="justify" class="grey-text">From the moment that you hand your case over to us, we will do our utmost to resolve the matter as soon as possible. Whether this concerns a straightforward debt recovery matter or a complicated legal conflict, we will unburden you.
                                </p>
                                <h3 class="mb-5 dark-grey-text title font-weight- wow fadeIn" data-wow-delay="0.2s">
                                    <strong>DEBT COLLECTION</strong>
                                </h3>
                                <p align="justify" class="grey-text">Unpaid invoices? Hand your debt recovery matter over as soon as possible and we will immediately go to work. After you have handed over your debt recovery matter, you will receive a confirmation by email that your debt recovery assignment has been duly received. Subsequently, you will receive our confirmation letter, in which your file handler indicates that we have accepted your instructions. We will also share some practical matters concerning contact with your debtor.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </main> 
                           
            </>
            );
    };

            export default Appro;

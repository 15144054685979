

const Contact = () => {
    return (
        <>
            <section>
                <div id="map-container-demo-section" class="map-container-section mb-4" style={{ "height": "500px" }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.720089051879!2d85.00719831486383!3d24.805036560757333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x57ed49e1e3032cf4!2sSisodiya%20Niwas!5e0!3m2!1sen!2sin!4v1624089265384!5m2!1sen!2sin" frameborder="0" style={{"border":"0", "height":"100%", "width":"100%"}} ></iframe>
                </div>
            </section>
            <main>
                <div class="container-fluid mb-5">
                    <div class="row" style={{ "marginTop": "-100px" }}>
                        <div class="col-md-12">
                            <div class="card pb-5">
                                <div class="card-body">
                                    <div class="container">
                                        <section class="text-center">
                                            <h2 class="font-weight-bold title h1 my-5">Contact us</h2>

                                            <p class="grey-text w-responsive mx-auto mb-5"></p>

                                            <div class="row pt-5">
                                                <div class="col-md-8 col-xl-9 mb-4">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="md-form mb-0">
                                                                    <div class="md-form mb-0">
                                                                        <input type="text" id="contact-name" class="form-control" />
                                                                        <label for="contact-name" class="">Your name</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="md-form mb-0">
                                                                    <div class="md-form mb-0">
                                                                        <input type="text" id="contact-email" class="form-control" />
                                                                        <label for="contact-email" class="">Your email</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="md-form mb-0">
                                                                    <input type="text" id="contact-Subject" class="form-control" />
                                                                    <label for="contact-Subject" class="">Subject</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="md-form mb-0">
                                                                    <textarea type="text" id="contact-message" class="md-textarea form-control"
                                                                        rows="3"></textarea>
                                                                    <label for="contact-message">Your message</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>

                                                    <div class="text-center text-md-left mt-4">
                                                        <a class="btn pink-gradient btn-rounded" style={{"color": "red"}}>Send</a>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 col-xl-3">
                                                    <ul class="list-unstyled contact-icons">
                                                        <li>
                                                            <i class="fas fa-map-marker-alt fa-2x orange-text"></i>
                                                            <p>Sisodiya Niwas Lala Babu Road, New Godown, Gaya (Bihar)- 823001</p>
                                                        </li>

                                                        <li>
                                                            <i class="fas fa-phone fa-2x orange-text"></i>
                                                            <p>0631-2225113</p>
                                                        </li>

                                                        <li>
                                                            <i class="fas fa-envelope fa-2x orange-text"></i>
                                                            <p>info@mbspvtltd.com</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
        </>
    );
};

export default Contact;
import React from "react";
// import "../layout/css/crousal.css";
// import "./css/bootstrap.min.css";
// import "./css/mdb.min.css";
// import "./css/mdb.css";
// import "./css/bootstrap.css";
// import "./css/crousal.css";
// import "../node_modules/bootstrap/dist/js/jquery-3.4.1.min.js";
// import "../node_modules/bootstrap/dist/js/popper.min.js";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";


const Home = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false">
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner hide" role="listbox">
                    <div class="carousel-item active">
                        <div class="view" >
                            <img src="./img/slide00.jpg" height={"700px"} width={"1520px"} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center" >
                                <img src="./img/work.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12" >
                                    <li>
                                        <h4 class="display-8 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }}
                                            data-wow-delay="0.3s">Welcome To Matritaw Bhawani Services</h4><br/><br/><br/>
                                        <h3 class="display-5 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Founders</h3>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": '500px', "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <table style={{ "marginLeft": "100px" }} >
                                            <tr>
                                                <td><img src="./dir/3.jpg" alt="Avatar" style={{ "width": "150px", "borderRadius ": "50%", "padding": "10px" }} /></td>
                                                <td><img src="./dir/1.jpg" alt="Avatar" style={{ "width": "150px", "borderRadius ": "50%", "padding": "10px" }} /></td>
                                                <td><img src="./dir/2.jpg" alt="Avatar" style={{ "width": "150px", "borderRadius ": "50%", "padding": "10px" }} /></td>
                                            </tr>
                                            <tr>
                                                <td>Mr. Ajeet Kumar Singh</td>
                                                <td>Mr. Pankaj Kumar</td>
                                                <td>Mr. Ajay Kumar Sinha</td>
                                            </tr>
                                        </table>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" height={"700px"} width={"1520px"} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": '500px', "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>

            <main class="body_cont">
                <div class="container">
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-lg-5">
                            <div class="col-lg-6 col-md-12 mb-3 wow fadeIn" data-wow-delay="0.4s">
                                <img src="./img/work2.png" class="img-fluid rounded" alt="My photo" />
                            </div>
                            <div class="col-lg-6 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s" >
                                <h1 class="mb-5 title  wow fadeIn grey-text" data-wow-delay="0.2s">
                                    <strong>About Us</strong>
                                </h1>
                                <p align="justify" class="grey-text">
                                    <span style={{ "color": "red" }} >Matritaw Bhawani Services</span> (MBS) Private Limited is an asset resolution company, which provides complete debt recovery solutions with proactive legal actions. MBS focuses on strategically timed legal intervention based on ageing of the delinquent account with emphasis on following due process of law, adhering to all judicial, regulatory and statutory guidelines.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="container-fluid" style={{ "backgroundColor": "#f4f4fa" }}>
                    <div class="container py-4">
                        <section class="my-3 pb-3">
                            <h3 class="text-center title my-5 pt-4 pb-5 dark-grey-text font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                <strong>What makes you choose us over the rest Debt Collection Agencies?</strong>
                            </h3>
                            <div class="row wow fadeIn" data-wow-delay="0.4s">
                                <div class="col-md-4 mb-5 text-center">
                                    <div class="card card-body text-left white hoverable">
                                        <p class="title font-weight-bold dark-grey-text text-uppercase spacing mt-4 mx-2">
                                            <i class="fas fa-square orange-text mr-2" aria-hidden="true"></i>
                                            <strong>Professional Services</strong>
                                        </p>
                                        <p class="grey-text font-small mx-4" style={{ "textAlign": "justify" }}>We assure you to provide high quality, 24X7 debt collection services with the assistance of a diligent and highly professional team. Easy monitoring of your case and progress with our project manager.</p>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-5 text-center">
                                    <div class="card card-body text-left white hoverable">
                                        <p class="title font-weight-bold dark-grey-text text-uppercase spacing mt-4 mx-4">
                                            <i class="fas fa-square orange-text mr-2" aria-hidden="true"></i>
                                            <strong>Improved Cash Flow</strong>
                                        </p>
                                        <p class="grey-text font-small mx-4" style={{ "textAlign": "justify" }}>Since our team and our debt collection agency is adept at providing debt collections within a short time in an effective manner; we can ascertain better cash flow thus, profiting the business. <br /></p>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-5 text-center" >
                                    <div class="card card-body text-left white hoverable">
                                        <p class="title font-weight-bold dark-grey-text text-uppercase spacing mt-4 mx-4">
                                            <i class="fas fa-square orange-text mr-2" aria-hidden="true"></i>
                                            <strong>Higher Productivity</strong>
                                        </p>
                                        <p class="grey-text font-small mx-2" style={{ "textAlign": "justify" }}>Outsourcing debt recovery services to us, company can dedicate more time, resources, and effort towards realizing the crucial goals with a strategic and focused approach. This ensures accelerated growth and higher productivity.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="container"><hr />
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-lg-5">
                            <div class="col-lg-6 col-md-12 mb-3 wow fadeIn" data-wow-delay="0.4s">
                                <img src="./img/work0.png" class="img-fluid rounded" alt="My photo" />
                            </div>
                            <div class="col-lg-6 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s" style={{ "marginTop": "00px" }} >
                                <h1 class="mb-5 title  wow fadeIn grey-text" data-wow-delay="0.2s">
                                    <strong>
                                        <span style={{ "color": "red" }}>Debt</span> Collection Services
                                    </strong>
                                </h1>
                                <p align="justify" class="grey-text">
                                    An enormous number of businesses around the world are witnessing extreme financial situations as a result of an increasing amount of debt, which can have a devastating effect on businesses. Debt collection is a complex and time-consuming process that can avert organizations in accomplishing major business goals. To resolve this issue, companies hire debt recovery agency. They will go the extra mile to provide proper solutions for recovery of debt and increasing cash flow business. MBS is a leading debt collection services company having operation offices around the globe. We have a team of professional legal debt collection and litigation who have vast experience in debt recovery services for B2B and B2C businesses around the globe.
                                </p>
                            </div>
                        </div>
                    </section>
                    <hr />
                    <section id="about2" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-5 wow fadeIn" data-wow-delay="0.4s">
                            <div class="col-lg-6 col-md-12 mb-5 text-left">
                                <h1 class="mb-5 title  wow fadeIn" data-wow-delay="0.2s">
                                    <strong>
                                        <span style={{ "color": "red" }}>Debt</span> Collection Process
                                    </strong>
                                </h1>
                                <h6>Case Assessment</h6>
                                <p align="justify" class="grey-text">Associated with the best lawyers in the industry the legal process is initiated through writs of attachment for the appointment of a receiver are often prepared, filed & served simultaneously with the summons and complaints.</p>
                                <h6>Pre-Litigation Advice</h6>
                                <p align="justify" class="grey-text">Debt advice provided to debtors is firm but friendly. Upon receipt of a claim, our debt collection agency will assign immediately to the dedicated team of Recovery Attorneys.</p>
                                <h6>Forthright Analysis</h6>
                                <p align="justify" class="grey-text">Our expert recovery agents designated to the client will be responsible to monitor bad debt collections and systematically measure the strategies.</p>
                            </div>
                            <div class="col-lg-6 col-md-12 mt-md-4 mt-lg-0 ml-auto mb-5 text-center">
                                <img src="./img/work1.png" class="img-fluid rounded z-depth-0" alt="My photo" />
                            </div>
                        </div>
                    </section>
                    <hr />
                </div>

                <div class="container">
                    <section id="contact" class="text-center my-5">
                        <h3 class="text-center title my-5 dark-grey-text font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                            <strong>Contact us</strong>
                        </h3>
                        <p class="grey-text w-responsive mx-auto mb-5 wow fadeIn" data-wow-delay="0.2s"></p>
                        <div class="row wow fadeIn" data-wow-delay="0.4s">
                            <div class="col-md-6 col-lg-6">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="md-form mb-0">
                                                <input type="text" id="form41" class="form-control" />
                                                <label for="form41" class="">Your name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="md-form mb-0">
                                                <input type="text" id="form52" class="form-control" />
                                                <label for="form52" class="">Your email</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="md-form mb-0">
                                                <input type="text" id="form51" class="form-control" />
                                                <label for="form51" class="">Subject</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="md-form mb-0">
                                                <textarea type="text" id="form76" class="md-textarea form-control" rows="3"></textarea>
                                                <label for="form76">Your message</label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="text-center text-md-left mb-5 mt-4">
                                    <a class="btn btn-rounded btn-white font-weight-bold" style={{ "color": "red" }}>Send</a>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-3">
                                <ul class="list-unstyled contact-icons float-md-right">
                                    <li>
                                        <i class="fas fa-map-marker-alt fa-2x title"></i>
                                        <p>Sisodiya Niwas Lala Babu Road, New Godown, Gaya (Bihar)- 823001</p>
                                    </li>

                                    <li>
                                        <i class="fas fa-phone fa-2x title"></i>
                                        <p>0631-2225113</p>
                                    </li>

                                    <li>
                                        <i class="fas fa-envelope fa-2x title"></i>
                                        <p>info@mbspvtltd.com</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-2 col-lg-1 mt-5">
                                <a href="./img/hdfc_life.jpg" target="_blank"> <img src="./img/GHS_2.gif" width="300px" /> </a>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>

    );
};

export default Home;
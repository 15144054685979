


const Board = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false" >
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/work.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{"width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <main>
                <div class="container">
                    <section class="team-section pb-5 mb-4">
                        <h3 class="my-5 pt-4  title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                            <strong>COMPOSITION OF BOARD</strong>
                        </h3>
                        <div class="row mb-lg-4 text-center text-md-left">
                            <div class="col-lg-4 col-md-12 mb-5">
                                <div class="col-md-12 float-left">
                                    <h4>
                                        <strong>Mr. Ajay Kumar Sinha:</strong>
                                    </h4>

                                    <p class="grey-text">Mr. Ajay Kumar Sinha: One of the founder member of SAVE Solutions, Born in the year 1970, in the state of Bihar in India. He is having 12 years of experience of financial sector, Bank, Enforcement, Recovery for the financial institution. Auction of movable/Immovable Property and Rural banking marketing.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mb-5">
                                <div class="col-md-12 float-left">
                                    <h4>
                                        <strong>Mr. Pankaj Kumar </strong>
                                    </h4>
                                    <p class="grey-text">Mr. Pankaj Kumar : One of the founder of Matritaw Bhawani Servcies Pvt Ltd and SAVE Solutions, Born in the year 1976, in the state of Bihar India, he having 8 years of comprehensive experience in stressed asset management, Insurance and Banking Industry. </p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mb-5">
                                <div class="col-md-12 float-left">
                                    <h4>
                                        <strong>Mr. Ajeet Kumar Singh</strong>
                                    </h4>
                                    <p class="grey-text">  Mr. Ajeet Kumar Singh : One of the founder of Matritaw Bhawani Servcies Pvt Ltd and SAVE Solutions, Born in the year 1976, in the state of Bihar in India, he is having 4 year experience in rural marketing strategy related to farm equipment. He is having 10 years of experience in financial services, micro finance, stressed asset management and banking sector.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>


        </>
    );
};

export default Board;

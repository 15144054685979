import React from "react";



const Ourserv = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false" >
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/work.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <main>
                <div class="container">
                    <br />
                    <h1 class=" font-weight-bold wow title fadeIn">SERVICES</h1>
                    <hr />
                    <br />
                    <h3>WHAT WE DO</h3>
                    <p class="grey-text">MBS offers a broad range of comprehensive services specifically designed to encompass the entire accounts receivable and credit process.</p>
                    <section class="team-section pb-5 mb-4">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Debt collection / Debt Recovery services
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>Successful debt collection and debt recovery programs rely on two core components: an experienced, knowledgeable, and well- trained staff and the use of state-of-the-art technology for debt collection and debt recovery. MBS’s debt collection associates are well prepared to professionally represent your business and ensure the maximum effectiveness and results our clients have come to expect. We efficiently negotiate an amicable settlement keeping in mind of our client’s expectations.</p>
                                        <p>MBS offers a wide range of International and domestic debt recovery and receivable management solutions in both Business to Business (B2B) and Business to Consumer (B2C) cases.</p>
                                        <p>We have our presence in almost all the major cities in India. We have a highly skilled team of in house attorneys that provide a quick, focused and workable solution to our clients with high rate of success. Along with our in-house debt collection /debt recovery attorneys, we have established working relationships with local attorneys in every major city in India to handle debt collections.
                                            Our talented attorneys bring a robust and imaginative approach to collecting your debt. We are able to resolve any legitimate dispute or reason for delay and create workable solutions to recover your money.
                                            In cases of most stubborn debtors we initiate legal action for the recovery of the bad debt that is legitimately due, of course with the prior approval of our clients. Our clients are timely updated of the progress of the cases during debt collection process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Debtor/Dealer Investigation
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                    <div class="card-body">
                                        <li> MBS  provides effective and efficient Debtor/Dealer Investigation Services providing the complete data about the debtors.</li>
                                        <li>When accounts are submitted for our Debt Collection Services, debtor investigation and tracing is automatically part of our collection process.</li>
                                        <li>The majority of our debtor investigation activities are handled by our experienced and well-trained collection team.</li>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Legal & Paralegal Services
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                    <div class="card-body">
                                        <p>We offer a wide range of Legal and Para-Legal services in all the business areas. We are completely equipped with our in house legal counsel and associate lawyers to provide the resolution for any complicated issues. Our services include but not limited to:</p>
                                        <li>Drafting of legal documents for the specific needs of our clients</li>
                                        <li>Legal advice on commercial complexities</li>
                                        <li>Mediation and Alternate dispute Resolution in Corporate Disputes</li>
                                        <li>Litigation by filing of Winding up Petitions, Recovery Suits, Summary Suits, Complaints under Sec 138 of Negotiable Instruments Act, 1881</li>
                                        <li>Representation before Board for Industrial and Financial Reconstruction (BIFR), Appellate Authority for Industrial and Financial Reconstruction (AAIFR)</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
};

export default Ourserv;

const Mission = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false" >
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{"marginTop": "-400px"}} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/work.png" style={{"paddingLeft": "800px"}} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{"marginTop": "-400px"}} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{"paddingLeft": "800px"}} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <main>
                <div class="container">
                    <h3 class="my-5 pt-4  title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                        <strong>MISSION & VISION</strong>
                    </h3>
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-5">
                            <div class="col-lg-5 col-md-12 mb-3 wow fadeIn" data-wow-delay="0.4s">
                                <img src="./img/mision.png" style={{"marginTop":"50px"}}  alt="My photo" />
                            </div>
                            <div class="col-lg-6 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <h3 class="mb-5 dark-grey-text title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                    <strong>Our Mission</strong>
                                </h3>
                                <p align="justify" class="grey-text">Rising cost of customer acquisition, requirements for satisfying consumer needs, burdens of overdue debt, and the need for effective customer retention etc., throw up never-ending challenges for any business. </p>
                                <p align="justify" class="grey-text">We believe in building long-lasting strategic relationship. We have sufficient experience and expertise needed establish a mutually beneficial relationship between clients and their debtors whereby we assist our clients in recovering outstanding amounts owing by implementing the most efficient means possible and take away your anxiety off issues regarding debt recovery and allow clients to focus on the core business processes and help them in transforming their business.
                                </p>
                            </div>
                        </div>
                    </section>
                    <hr/>

                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-5">
                            <div class="col-lg-6 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <h3 class="mb-5 dark-grey-text title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                    <strong>Our Vision</strong>
                                </h3>
                                <p align="justify" class="grey-text">Rising cost of customer acquisition, requirements for satisfying consumer needs, burdens of overdue debt, and the need for effective customer retention etc., throw up never-ending challenges for any business. </p>
                                <p align="justify" class="grey-text">We believe in building long-lasting strategic relationship. We have sufficient experience and expertise needed establish a mutually beneficial relationship between clients and their debtors whereby we assist our clients in recovering outstanding amounts owing by implementing the most efficient means possible and take away your anxiety off issues regarding debt recovery and allow clients to focus on the core business processes and help them in transforming their business.
                                </p>
                            </div>
                            <div class="col-lg-5 col-md-12 mb-3 wow fadeIn" data-wow-delay="0.4s">
                                <img src="./img/Vision0.png" style={{"marginTop": "50px"}}  alt="My photo" />
                            </div>
                        </div>
                    </section>
                    <hr />
                </div>
            </main>
            </>
            );
    };

export default Mission;

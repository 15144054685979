


const About = () => {
    return (
        <>
            <div id="carousel-example-3" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false" >
                <ol class="carousel-indicators">
                    <li data-target="#carousel-example-3" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-example-3" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="carousel-item active">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/work.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Using a professional approach, our aim is to accelerate and facilitate the recovery of unpaid debts</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="view">
                            <img src="./img/slide00.jpg" style={{ "marginTop": "-400px" }} />
                            <div class="h-100 d-flex justify-content-center align-items-center mask rgba-black-light white-text text-center">
                                <img src="./img/slide1.png" style={{ "paddingLeft": "800px" }} />
                                <ul class="list-unstyled animated fadeInUp col-md-12">
                                    <li>
                                        <h1 class="display-4 font-weight-bold wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s">Debt Collection</h1>
                                        <hr class="hr-light wow fadeIn w-20" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s" />
                                    </li>
                                    <li>
                                        <h4 class="my-4 wow fadeIn" style={{ "width": "500px", "textAlign": "left", "marginLeft": "100px" }} data-wow-delay="0.3s"> Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.</h4>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-example-3" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-example-3" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <main>
                <div class="container">
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-5">
                            <div class="col-lg-12 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <h3 class="mb-5 dark-grey-text title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                    <strong>ABOUT US</strong>
                                </h3>
                                <p align="justify" class="grey-text"><span style={{"color" :"red"}}>MATRITAW BHAWANI SERVICES PRIVATE LIMITED</span> is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.
                                    We offer cost-effective, efficient and ethical services suitable to all sizes of commercial organizations. We work towards removing the obstacles that stand between our client’s business and the money it’s owed, by maximizing the recovery of their outstanding debts.
                                    Our polite, persuasive, persistent but professional approach towards recovery collection produces far better debt recovery results than the traditional strong-arm tactics. Our simple approach produces quicker recovery payment from the debtors.
                                </p>
                                <p align="justify" class="grey-text">We subscribe to the ethical standards in debt collection and comprehend that the debt collection is part of a continuing relationship between the client and the debtor.
                                    We understand that by allotment an account to us for collection our clients also release their brand to us. Being conscious of this, we ensures that we protect one of the most important aspects of our clients business that is their brand equity.
                                    We understand the importance of confidentiality of private data of our clients, data security, and that is why we ensure that the data provided to us always remains confidential.
                                    MBS Pvt Ltd reflects our values and reinforces our commitment to the highest standards of integrity and honesty in business.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-5">
                            <div class="col-lg-4 col-md-12 mb-3 wow fadeIn" data-wow-delay="0.4s">
                                <img src="./img/obj.png"  alt="My photo" width="300px" />
                            </div>
                            <div class="col-lg-8 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <h3 class="mb-5 dark-grey-text title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                    <strong>OUR OBJECTIVE</strong>
                                </h3>
                                <li> Collection the recovery within the stipulated time frame allotted</li>
                                <li> To be proven by our clients as the agency of choice in terms of performance and results. </li>
                                <li> To have strong relationships with key organizations in every market segments. </li>
                                <li> To be an extension of overall our client’s business approach and an exclusive Recovery Solutions Partner.</li>
                            </div>
                        </div>
                    </section>
                    <section id="about" class="mt-5 mb-5 py-3 wow fadeIn" data-wow-delay="0.2s">
                        <div class="row pt-2 mt-5">
                            <div class="col-lg-7 ml-auto col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <h3 class="mb-5 dark-grey-text title font-weight-bold wow fadeIn" data-wow-delay="0.2s">
                                    <strong>RENDEZVOUS</strong>
                                </h3>
                                <li>  Initial contact with the client.</li>
                                <li> Judge the nature of client (defaulter borrower)</li>
                                <li>  Readiness to outsource Recovery </li>
                                <li> Inter-action vis-à-vis concept and offerings</li>
                                <li>Concurrence to work together.</li>
                                <li>Evaluation of Recovery.</li>
                                <li>Economical background of client.</li>
                            </div>
                            <div class="col-lg-5 col-md-12 mb-3 wow fadeIn" data-wow-delay="0.4s">
                                <img src="./img/RENDEZVOUS.png" alt="My photo" />
                            </div>
                        </div>
                    </section>
                </div>
            </main>

        </>
    );
};

export default About;

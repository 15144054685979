import React from "react";
import "../layout/css/bootstrap.min.css";
import "../layout/css/crousal.css";
// import "../layout/css/mdb.min.css";
//import "../layout/css/mdb.css";
import "../layout/css/bootstrap.css";
import "../../../node_modules/bootstrap/dist/js/jquery-3.4.1.min.js";
import "../../../node_modules/bootstrap/dist/js/popper.min.js";
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <>
            <nav class="navbar navbar-expand-lg navbar-light fixed-top scrolling-navbar white">
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.20.0/css/mdb.min.css" integrity="sha512-hj9rznBPdFg9A4fACbJcp4ttzdinMDtPrtZ3gBD11DiY3O1xJfn0r1U5so/J0zwfGOzq9teIaH5rFmjFAFw8SA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.20.0/css/mdb.css" integrity="sha512-hj9rznBPdFg9A4fACbJcp4ttzdinMDtPrtZ3gBD11DiY3O1xJfn0r1U5so/J0zwfGOzq9teIaH5rFmjFAFw8SA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.2/css/all.css" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-156231239-1"></script>
                <div class="container">
                    <img class="navbar-brand font-weight-bold title mt-3"  />
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
                        aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul class="list-unstyled navbar-nav mr-auto">
                            <li class="nav-item dropdown ml-4 mb-0">
                                { /*<Link class="nav-link  title" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="/Home"> Home </Link> */ }
                            </li>
                            <li class="nav-item dropdown ml-4">
                                <Link class="nav-link  title" to="/Home">Home </Link>
                            </li>
                            <li class="nav-item dropdown ml-4">
                                <a class="nav-link dropdown-toggle title" id="navbarDropdownMenuLink12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About US </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <Link class="dropdown-item" to="/Mission">OUR MISSION & VISION</Link>
                                    <Link class="dropdown-item" to="/Approach">OUR APPROACH</Link>
                                    <Link class="dropdown-item" to="/About">WHO WE ARE ?</Link>
                                    <Link class="dropdown-item" to="/Board">COMPOSITION OF BOARD</Link>
                                </div>
                            </li>

                            <li class="nav-item ml-4">
                                {/* <Link class="nav-link title" to={Ourserv} data-offset="90">Services</Link>  */}
                                <Link class="nav-link title" to="/Ourservice" data-offset="90">Services</Link>
                            </li>
                            <li class="nav-item dropdown ml-4">
                                <Link class="nav-link  title" to="/Recovery">Recovery Method </Link>
                            </li>

                            <li class="nav-item dropdown ml-4">
                                <Link class="nav-link  title" to="/Career">Career </Link>
                            </li>
                            <li class="nav-item dropdown ml-4">
                                <Link class="nav-link  title" to="/Contact">Contact Us </Link>
                            </li>
                        </ul>

                        <ul class="navbar-nav nav-flex-icons">
                            <li class="nav-item">
                                <a class="nav-link">
                                    <i class="fab fa-facebook-f title"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">
                                    <i class="fab fa-twitter title"></i>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link">
                                    <i class="fab fa-instagram title"></i>
                                </a>
                            </li>
                            <li class="nav-item ml-3">
                                <a class="btn pink-gradient btn-rounded btn-sm font-weight-bold" href="#contact" data-offset="90" style={{"color": "red"}}>Login</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
import React from "react";
//import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./component/pages/Navbar";
import Home from "./component/pages/Home";
import Footer from "./component/pages/footer";
import Ourserv from "./component/pages/Ourservice";
import Mission from "./component/pages/Mission";
import Appro from "./component/pages/Approach";
import About from "./component/pages/About";
import Board from "./component/pages/Board";
import Recovery from "./component/pages/Recovery";
import Career from "./component/pages/Career";
import Contact from "./component/pages/Contact";

function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/Home' component={Home} />
          <Route exact path='/Ourservice' component={Ourserv} />
          <Route exact path='/Mission' component={Mission} />
          <Route exact path='/Approach' component={Appro} />
          <Route exact path='/About' component={About} />
          <Route exact path='/Board' component={Board} />
          <Route exact path='/Recovery' component={Recovery} />
          <Route exact path='/Career' component={Career} />
          <Route exact path='/Contact' component={Contact} />
        </Switch>
        <Footer/>
      </Router>
    </>    
  );
};

export default App;
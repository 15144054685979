import React from "react";
import { Link } from 'react-router-dom';
// import "./css/bootstrap.min.css";
// import "./css/mdb.min.css";
// import "./css/mdb.css";
// import "./css/bootstrap.css";
// import "../node_modules/bootstrap/dist/js/jquery-3.4.1.min.js";
// import "../node_modules/bootstrap/dist/js/popper.min.js";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

const Footer = () => {
  return (
    <footer class="page-footer text-center text-md-left unique-color-dark pt-0">
      <div style={{ "backgroundColor": "#F33A6A" }}>
        <div class="container">
          <div class="row py-4 d-flex align-items-center">
            <div class="col-md-6 col-lg-5 text-center text-md-left mb-md-0">
              <h6 class="mb-4 mb-md-0 white-text">Get connected with us on social networks!</h6>
            </div>
            <div class="col-md-6 col-lg-7 text-center text-md-right">
              <a class="p-2 m-2 fa-lg fb-ic ml-0">
                <i class="fab fa-facebook-f white-text mr-lg-4"> </i>
              </a>
              <a class="p-2 m-2 fa-lg tw-ic">
                <i class="fab fa-twitter white-text mr-lg-4"> </i>
              </a>
              <a class="p-2 m-2 fa-lg gplus-ic">
                <i class="fab fa-google-plus-g white-text mr-lg-4"> </i>
              </a>
              <a class="p-2 m-2 fa-lg li-ic">
                <i class="fab fa-linkedin-in white-text mr-lg-4"> </i>
              </a>
              <a class="p-2 m-2 fa-lg ins-ic">
                <i class="fab fa-instagram white-text mr-lg-4"> </i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 mb-4 text-center text-md-left">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mb-5">
            <h6 class="spacing font-weight-bold">
              <strong><b>MBS PVT LTD </b></strong>
              <hr style={{ "height": "1px", "border-width": "0", "color": "red", "background-color": "#F33A6A" }} />
              {/* <hr class="pink accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ "width": "60px" }} /> */}
            </h6>
            <p align="justify" >
              <img src="./img/logo.jpg" width="80px" height="90" align="left" style={{ border: "#D9D785 1px dashed", marginRight: "10px" }} />
              Matritaw Bhawani Services Private Limited is professionally managed and highly motivated enforcement, Business Recovery, Investigative and Detective Company head quartered at Gaya, Bihar.
            </p>
          </div>

          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-5">
            <h6 class="spacing font-weight-bold">
              <strong><b>Useful links</b></strong>
              <hr style={{ "height": "1px", "border-width": "0", "color": "red", "background-color": "#F33A6A" }} />
            </h6>
            {/* <hr class="pink accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ "width": "60px" }} /> */}
            <p>
              <Link to="/Recovery" data-offset="90">RECOVERY METHOD</Link>
            </p>
            <p>
              <Link to="/Ourservice" >Services</Link>
            </p>
            <p>
              <Link to="/About">ABOUT US </Link>
            </p>
            <p>

            </p>
          </div>

          <div class="col-md-4 col-lg-3 col-xl-3">
            <h6 class="spacing font-weight-bold">
              <strong><b>Contact</b></strong>
              <hr style={{ "height": "1px", "border-width": "0", "color": "red", "background-color": "#F33A6A" }} />
            </h6>
            {/* <hr class="pink accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ "width": "60px" }} /> */}
            <p>
              <i class="fas fa-home mr-3"></i>Sisodiya Niwas Lala Babu Road, New Godown, Gaya (Bihar)- 823001</p>
            <p>
              <i class="fas fa-envelope mr-3"></i> info@mbspvtltd.com</p>
            <p>
              <i class="fas fa-phone mr-3"></i> 0631-2225113</p>

          </div>
        </div>
      </div>

      <div class="footer-copyright text-center py-3">
        <div class="container-fluid">
          © 2021 Copyright: <a href="http://mbspvtltd.com/" target="_blank"> MBSPVTLTD.com </a>
        </div>
      </div>


    </footer>
  );
};

export default Footer;